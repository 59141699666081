import { Alert, Box, Typography } from "@mui/material";
import { accountStyles } from "./pageStyles";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getTenantDetails } from "../apiHelper";
import PersonComponent from "../components/PersonComponent";
import { useAuth0 } from "@auth0/auth0-react";
const Account = () => {
  const classes = accountStyles();
  const location = useLocation();
  const [accountData, setAccountData] = useState({});
  const [provisionMessage, setProvisionMessage] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    if (location?.state?.accountId) {
      getAccessTokenSilently().then((t) => {
        getTenantDetails(t, { accountId: location?.state?.accountId }).then(
          (data) => setAccountData(data)
        );
      });
    }
  }, [location.state]);
  return (
    <Box className={classes.outerContainer}>
      <Box className={classes.title}>
        <Typography variant="h1">
          {accountData.accountDetails && accountData.accountDetails.accountName}
        </Typography>
      </Box>
      {provisionMessage && (
        <Box className={classes.messageContainer}>
          <Alert
            severity={
              provisionMessage.includes("Email sent succesfully to")
                ? "success"
                : "error"
            }
          >
            {provisionMessage}
          </Alert>
        </Box>
      )}
      <Box className={classes.users}>
        {accountData.members
          ? accountData.members.map((person, i) => (
              <PersonComponent
                person={person}
                key={i}
                setProvisionMessage={setProvisionMessage}
              />
            ))
          : null}
      </Box>
    </Box>
  );
};

export default Account;
